/* eslint-disable unused-imports/no-unused-vars */
import { gql } from '@apollo/client';
import { useConfig } from '@drugfreesleep/config/useConfig';
import { getApolloClient } from '@drugfreesleep/modules/graphql/apollo';
import { useProgramWithPayloadQuery } from '@drugfreesleep/modules/graphql/generated-types';
import useProgramSlug from '@drugfreesleep/utils/useProgramSlug';
import { useMemo } from 'react';

export const useProgram = (
  sections: string[],
  poll?: boolean,
  programId?: number
) => {
  const config = useConfig();
  let programSlug = useProgramSlug() as string;
  // const programConfig = useProgramConfig(programSlug);
  if (programId) {
    programSlug = Object.values(config.programs).find(
      (program) => program.id === programId
    )?.slug as string;
  }

  const queryOptions = {
    variables: {
      organizationSlug: config?.programs[programSlug].organizationSlug,
      programSlug: config?.programs[programSlug].slug,
      sections,
      versionId: config?.programs[programSlug].versionId,
    },
    skip: !config,
    pollInterval: poll ? 500 : undefined,
  };
  const { data, loading, error } = useProgramWithPayloadQuery(queryOptions);
  return {
    program: data?.programWithPayload?.program,
    interactions: data?.programWithPayload?.interactions,
    loading,
    error,
  };
};

const splitSlug = (input: string) => {
  if (!input) {
    return {
      organizationSlug: '',
      programSlug: '',
    };
  }
  const [organizationSlug, programSlug] = input.includes('__')
    ? input.split('__')
    : ['weller-labs', input];
  return { organizationSlug, programSlug };
};

export const useProgramConfig = (slug?: string) => {
  const client = getApolloClient();
  const config = useConfig();
  const { organizationSlug, programSlug } = splitSlug(slug || '');

  const queryOptions = {
    client,
    variables: {
      organizationSlug,
      programSlug,
      sections: ['config'],
      versionId: -1,
    },
    skip: !slug,
  };
  const { data, loading, error } = useProgramWithPayloadQuery(queryOptions);
  let program = null;
  const programConfig = useMemo(() => {
    let pc = null;
    if (data?.programWithPayload) {
      program = data.programWithPayload.program;
      /*
     * Example program.payload.config
     *
      "display": {
        "logo": "audio",
        "name": "The 5 Sense Method" // overrides program.name for display
      },
      "components": {
        "about": {
          "id": 29
        },
        "homepage": {
          "id": 62
        }
      },
    */
      pc = {
        ...program.payload.config,
        id: program.id,
        organizationSlug,
        display: {
          ...(program.payload.config.display
            ? program.payload.config.display
            : {}), // Create a new object for display
          logo: program.payload?.config?.display?.logo || 'audio', // Ensure logo is set
          name: program.payload?.config?.display?.name || program.name, // Ensure name is set
        },
        components: {
          ...config.components,
          ...program.payload.config.components,
        },
      };
    }
    return pc;
  }, [data?.programWithPayload?.program.id]);
  return {
    program,
    programConfig,
    loading,
    error,
  };
};

// export const ProgramAtVersion = gql`
//   query ProgramAtVersion($id: Int!, $versionId: Int!) {
//     program(id: $id) {
//       __typename
//       id
//       userId
//       orgId
//       type
//       name
//       slug
//       payload
//       meta
//       isPublic
//       createdAt
//       updatedAt
//       atVersion(input: { versionId: $versionId }) {
//         id
//         userId
//         orgId
//         type
//         name
//         slug
//         payload
//         meta
//         isPublic
//         createdAt
//         updatedAt
//       }
//     }
//   }
// `;
export const Program = gql`
  query Program($id: Int!) {
    program(id: $id) {
      __typename
      id
      userId
      orgId
      type
      name
      slug
      payload
      meta
      isPublic
      createdAt
      updatedAt
    }
  }
`;

export const ProgramWithPayload = gql`
  query ProgramWithPayload(
    $organizationSlug: String!
    $programSlug: String!
    $sections: [String!]!
    $versionId: Int
  ) {
    programWithPayload(
      input: {
        programSlug: $programSlug
        organizationSlug: $organizationSlug
        sections: $sections
        versionId: $versionId
      }
    ) {
      program {
        id
        name
        payload
        orgId
      }
      interactions {
        id
        name
        payload
      }
    }
  }
`;
