import type { MutationFunctionOptions } from '@apollo/client';
import { useConfig } from '@drugfreesleep/config/useConfig';
import useProgramSlug from '@drugfreesleep/utils/useProgramSlug';
import useSubscriptionIds from '@drugfreesleep/utils/useSubscriptionIds';
import { useCallback } from 'react';

import { useLogger } from '../analytics/useImpression';
import { setAuth } from '../auth/auth';
import type {
  Exact,
  UserRegistrationMutation,
} from '../graphql/generated-types';
import {
  SubscriptionStateEnum,
  useCreateSubscriptionMutation,
  useUserRegistrationMutation,
} from '../graphql/generated-types';
import { useAuth } from './useAuth';

export interface RegistrationFormValues {
  username: string;
  password: string;
  remember: boolean;
}

// return type for the hook that matches useUserRegistrationMutation
type RegistrationMutation = ReturnType<typeof useUserRegistrationMutation>;

export const useRegistration = (): RegistrationMutation => {
  const log = useLogger();
  const auth = useAuth();
  const config = useConfig();
  const programSlug = useProgramSlug() ?? '';
  const { orgId, therapistId } = useSubscriptionIds();
  const [register, { loading, error, ...rest }] = useUserRegistrationMutation();
  const [subscribe, { loading: sloading, error: sError }] =
    useCreateSubscriptionMutation();

  const onSubmit = useCallback(
    async (
      options?: MutationFunctionOptions<
        UserRegistrationMutation,
        Exact<{
          username: string;
          password: string;
        }>
      >
    ) => {
      const { data, errors } = await register(options);
      const allErrors = [...(errors || [])];
      if (
        data?.registerUser &&
        data?.registerUser?.user?.id &&
        data?.registerUser?.user?.username &&
        data?.registerUser?.jwt
      ) {
        const userId = data.registerUser.user.id;
        // set user
        setAuth(
          data?.registerUser.user,
          data?.registerUser?.jwt,
          data.registerUser.refresh as string
        );
        auth.login(data?.registerUser.user, data?.registerUser.jwt);
        try {
          // create subscription
          await subscribe({
            variables: {
              input: {
                subscription: {
                  type: 'open',
                  state: SubscriptionStateEnum.Active,
                  userId,
                  programId: config?.programs[programSlug].id,
                  orgId: orgId as number,
                  meta: {
                    url: window.location.href,
                  },
                  payload: {
                    therapistId,
                  },
                },
              },
            },
          });
        } catch (e) {
          await log('subscription error', {
            subtype: 'error',
            payload: {
              message: 'Error creating subscription',
              error: e,
            },
          });
        }
      }
      return {
        data,
        loading,
        errors: allErrors.length === 0 ? undefined : allErrors,
      };
    },
    [programSlug]
  );

  return [
    onSubmit,
    { loading: loading || sloading, error: error || sError, ...rest },
  ];
};
