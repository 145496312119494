import {
  useQueryState,
  useUpsertState,
} from '@drugfreesleep/app/(program)/query';
import { useSessionViewerState } from '@drugfreesleep/components/sessionViewerProvider/useSessionViewerState';
import { useConfig } from '@drugfreesleep/config/useConfig';
import useProgramSlug from '@drugfreesleep/utils/useProgramSlug';
import useWindowSize from '@drugfreesleep/utils/useWindowSize';
import type { ConfigType } from '@markdoc/markdoc';
import { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';

import { useAuth } from '../authentication/useAuth';
import { StateStateEnum } from '../graphql/generated-types';
import type { RadioOption } from './RadioSelect';
import { useFormState } from './useFormState';

const config: ConfigType = {
  tags: {
    select: {
      render: 'SelectableList',
      children: ['option'],
      attributes: {
        name: {
          type: String,
          required: true,
        },
        other: {
          type: [Boolean, String],
          default: false,
        },
        multiple: {
          type: [Boolean, Number],
          default: false,
        },
        ids: {
          type: String,
          required: false,
        },
        required: {
          type: Number,
          required: false,
        },
      },
    },
  },
};

export interface SelectableListProps {
  name: string;
  ids?: string;
  options: RadioOption[];
  other: boolean;
  multiple?: boolean | number;
  required?: number;
}

interface IOptionsObject {
  [k: string]: RadioOption;
}

const SelectableList: React.FC<SelectableListProps> = ({
  name,
  options,
  other,
  multiple,
  required = 1,
}) => {
  const optionsObject: IOptionsObject = {};
  options.map((option) => {
    optionsObject[option.id] = option;
    return null;
  });
  const auth = useAuth();
  const [upsertState] = useUpsertState();
  const savedConfig = useConfig();
  const programSlug = useProgramSlug() as string;
  const [queryState] = useQueryState({
    id: savedConfig.programs[programSlug].id,
  });
  const { isMobile } = useWindowSize();
  // Initialize useFormState to manage this component's state
  const {
    componentState,
    setComponentState,
    index: pathIndex,
  } = useFormState('select', name, {
    required,
    selected: [],
  });

  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    componentState.value.selected ?? []
  );
  const [otherText, setOtherText] = useState(componentState.value.otherText);

  const { setDisableNext, setEnableNext } = useSessionViewerState(
    name,
    pathIndex
  );

  useEffect(() => {
    if (selectedOptions.length < required) {
      setDisableNext();
    } else {
      setEnableNext();
    }
  }, [selectedOptions]);

  const handleChange = () => {
    setComponentState({
      ...componentState.value,
      selected: selectedOptions,
      otherText,
    });
  };

  const handleStateChange = async () => {
    if (selectedOptions.length === 0) {
      return;
    }
    if (!isUndefined(options[0].interactionId)) {
      if (selectedOptions.findIndex((selected) => selected === null) >= 0) {
        options.map(async (option) => {
          if (option.interactionId !== null) {
            const currentInteractionState = queryState({
              id: option.interactionId as number,
            });
            await upsertState({
              ...(currentInteractionState || {}),
              userId: auth.user?.id as number,
              programId: savedConfig.programs[programSlug].id as number,
              interactionId: option.interactionId as number,
              state: StateStateEnum.Skipped,
            });
          }
          return null;
        });
      } else {
        const selectedOptionInterIds: number[] = [];
        const notSelectedOptionInterIds: number[] = [];
        options.map((option) => {
          if (option.interactionId !== null) {
            if (
              selectedOptions.findIndex((selected) => selected === option.id) >=
              0
            ) {
              selectedOptionInterIds.push(option.interactionId as number);
            } else {
              notSelectedOptionInterIds.push(option.interactionId as number);
            }
          }
          return null;
        });

        selectedOptionInterIds.map(async (interId) => {
          const currentInteractionState = queryState({
            id: interId as number,
          });
          await upsertState({
            ...(currentInteractionState || {}),
            userId: auth.user?.id as number,
            programId: savedConfig.programs[programSlug].id as number,
            interactionId: interId as number,
            state: StateStateEnum.New,
          });
        });

        notSelectedOptionInterIds.map(async (interId) => {
          const currentInteractionState = queryState({
            id: interId as number,
          });
          await upsertState({
            ...(currentInteractionState || {}),
            userId: auth.user?.id as number,
            programId: savedConfig.programs[programSlug].id as number,
            interactionId: interId as number,
            state: StateStateEnum.Skipped,
          });
        });
      }
    }
  };

  useEffect(() => {
    handleChange();
    handleStateChange();
  }, [selectedOptions, otherText]);

  // Update the handler to use setComponentState from useFormState
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    let newSelectedOptions: string[] = multiple ? [...selectedOptions] : [];

    if (checked) {
      newSelectedOptions.push(value);
    } else {
      newSelectedOptions = newSelectedOptions.filter(
        (option) => option !== value
      );
    }

    // If there's a numerical limit, enforce it
    if (typeof multiple === 'number' && newSelectedOptions.length > multiple) {
      newSelectedOptions = newSelectedOptions.slice(0, multiple);
    }
    setSelectedOptions(newSelectedOptions);
  };

  // Update the handler for the "other" input field
  const handleOtherInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOtherText(event.target.value);
  };

  const isMultiple = multiple === true || typeof multiple === 'number';

  return (
    <div className="flex w-full justify-center">
      <div className={`mb-10 ${isMobile ? 'w-full px-4' : 'w-[28rem]'}`}>
        {options.map((option, index) => (
          <label
            key={index}
            className={`flex items-center ${
              isMobile ? 'mb-5 rounded-lg border p-2 text-lg' : 'mb-2'
            }`}
          >
            <input
              type={isMultiple ? 'checkbox' : 'radio'}
              name={name}
              value={option.id}
              checked={selectedOptions.includes(option.id)}
              onChange={handleCheckboxChange}
              disabled={
                !isMultiple &&
                typeof multiple === 'number' &&
                selectedOptions.length >= multiple &&
                !selectedOptions.includes(option.id)
              }
              className={`form-checkbox ${isMobile ? 'h-6 w-6' : 'h-4 w-4'}`}
            />
            <div className="ml-2">{option.label}</div>
          </label>
        ))}
        {other && (
          <>
            <label
              className={`flex items-center ${
                isMobile ? 'mb-4 rounded-lg border p-4 text-lg' : 'mb-2'
              }`}
            >
              <input
                type={isMultiple ? 'checkbox' : 'radio'}
                name={`${name}-other`}
                value="other"
                checked={selectedOptions.includes('other')}
                onChange={handleCheckboxChange}
                className={`form-checkbox ${isMobile ? 'h-6 w-6' : 'h-4 w-4'}`}
              />
              <div className="ml-2">Other:</div>
            </label>
            {selectedOptions.includes('other') && (
              <input
                type="text"
                value={otherText}
                onChange={handleOtherInputChange}
                className={`ml-2 ${
                  isMobile
                    ? 'w-full rounded-lg border p-4 text-lg'
                    : 'w-auto rounded border p-2 text-base'
                }`}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { SelectableList, config as selectableListConfig };
