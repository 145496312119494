/* eslint-disable no-console */
import { useConfig } from '@drugfreesleep/config/useConfig';
import useProgramSlug from '@drugfreesleep/utils/useProgramSlug';
import useSubscriptionIds from '@drugfreesleep/utils/useSubscriptionIds';

import { useLogger } from '../analytics/useImpression';
import { setAuth } from '../auth/auth';
import {
  SubscriptionStateEnum,
  useAuthenticateUserMutation,
  useCreateSubscriptionMutation,
} from '../graphql/generated-types';
import { useAuth } from './useAuth';

export const useLogin = () => {
  const log = useLogger();
  const auth = useAuth();
  const config = useConfig();
  const programSlug = useProgramSlug() as string;
  const { orgId, therapistId } = useSubscriptionIds();
  const [authenticateUser, { loading, error }] = useAuthenticateUserMutation();
  const [subscribe, { loading: sLoading, error: sError }] =
    useCreateSubscriptionMutation();

  const login = async (
    username: string,
    password: string,
    remember = false
  ) => {
    const { data } = await authenticateUser({
      variables: {
        username,
        password,
        // remember,
      },
    });
    // set token in local storage
    // redirect to dashboard
    if (
      data?.authenticateUser &&
      data?.authenticateUser?.user?.id &&
      data?.authenticateUser?.user?.username &&
      data?.authenticateUser?.jwt
    ) {
      const jwt = data?.authenticateUser?.jwt;
      // const refresh = data?.authenticateUser?.refresh as string;
      const userId = data.authenticateUser.user.id;
      // set auth in local storage
      setAuth(
        data?.authenticateUser.user,
        jwt,
        data.authenticateUser.refresh as string,
        remember
      );
      // if (data.authenticateUser.userProfile && isBoolean(data.authenticateUser.userProfile.emailVerified)) {
      //   jwtControl.setEmailVerified(data.authenticateUser.userProfile.emailVerified);
      // }
      auth.login(data?.authenticateUser.user, jwt);
      if (
        window.location.href.includes('register') &&
        data.authenticateUser.subscriptions?.findIndex(
          (sub) => sub?.programId === config?.programs[programSlug].id
        ) === -1
      ) {
        try {
          // create subscription
          await subscribe({
            variables: {
              input: {
                subscription: {
                  type: 'open',
                  state: SubscriptionStateEnum.Active,
                  userId,
                  programId: config?.programs[programSlug].id,
                  orgId: orgId as number,
                  meta: {
                    url: window.location.href,
                  },
                  payload: {
                    therapistId,
                  },
                },
              },
            },
          });
        } catch (e) {
          await log('subscription error', {
            subtype: 'error',
            payload: {
              message: 'Error creating subscription',
              error: e,
            },
          });
        }
      }

      return jwt;
    }
    return false;
  };

  return {
    login,
    loading: loading || sLoading,
    error: error || sError,
  };
};
