import useProgramSlug from '@drugfreesleep/utils/useProgramSlug';
import useWindowSize from '@drugfreesleep/utils/useWindowSize';
import type { ConfigType } from '@markdoc/markdoc';
import classNames from 'classnames';
import { Button } from 'flowbite-react';
import Link from 'next/link';
import React from 'react';
import { IoArrowForwardOutline } from 'react-icons/io5';

export const HeroConfig: ConfigType = {
  tags: {
    hero: {
      render: 'Hero',
      children: [],
      attributes: {
        className: {
          type: String,
          required: false,
        },
        title: {
          type: String,
          required: true,
        },
        desc: {
          type: String,
          required: true,
        },
        img1: {
          type: String,
          required: true,
        },
        img2: {
          type: String,
          required: false,
        },
      },
    },
  },
};

interface HeroProps {
  className?: string;
  title: string;
  desc: string;
  img1: string;
  img2?: string;
}

export const Hero: React.FC<HeroProps> = ({
  className,
  title,
  desc,
  img1,
  img2,
}) => {
  const pSlug = useProgramSlug();
  const { isMobile } = useWindowSize();

  return (
    <div className="relative  ">
      <div
        className={classNames(
          'bg-gradient-to-r from-stone-50 via-amber-50 to-violet-200  justify-evenly flex flex-col-reverse md:flex-row h-80vh md:h-60vh px-7',
          className
        )}
        style={{ borderBottomLeftRadius: '9rem' }}
      >
        <div className="mt-5 w-full pt-5 md:w-1/2 md:pl-10 md:pr-5">
          <div
            className="my-auto text-5xl font-semibold text-blue-800 md:text-6xl md:font-bold"
            style={{ lineHeight: '4rem' }}
          >
            {title}
          </div>
          <div
            className="py-10 text-2xl text-stone-800"
            style={{ lineHeight: '2rem' }}
          >
            {desc}
          </div>
          <div className="flex justify-start pb-12">
            <Link href={`/p/${pSlug}/course`}>
              <Button
                style={{
                  width: '250px',
                  borderRadius: '5rem',
                  backgroundColor: 'rgb(30 64 175)',
                }}
              >
                <div className="flex items-center rounded-full">
                  <div className="mr-1 text-xl font-bold">Start the course</div>
                  <div>
                    <IoArrowForwardOutline />
                  </div>
                </div>
              </Button>
            </Link>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <div className="relative w-full pb-10 md:pt-20 ">
            {isMobile ? (
              <div>
                <div className="px-10 pt-7 md:mb-0 md:pr-20">
                  <img
                    src={img1}
                    alt="Woman using phone"
                    width={188}
                    height={182}
                    className=""
                  />
                </div>
                <div className="absolute top-32 left-40">
                  {img2 && (
                    <img
                      src={img2}
                      alt="Woman sleeping"
                      width={136}
                      height={136}
                      className=""
                    />
                  )}
                </div>
              </div>
            ) : (
              <div>
                <div className="px-10 md:mb-0 md:pr-20">
                  <img
                    src={img1}
                    alt="Woman using phone"
                    width={300}
                    height={300}
                    className=""
                  />
                </div>
                <div className="absolute top-64 left-52">
                  {img2 && (
                    <img
                      src={img2}
                      alt="Woman sleeping"
                      width={320}
                      height={320}
                      className=""
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
