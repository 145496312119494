import useProgramSlug from '@drugfreesleep/utils/useProgramSlug';
import type { ConfigType } from '@markdoc/markdoc';
import classNames from 'classnames';
import { Button } from 'flowbite-react';
import Image from 'next/image';
import Link from 'next/link';
import care from 'public/assets/images/WellerLabs.png';
import React from 'react';
import { IoMdMoon } from 'react-icons/io';
import { IoArrowForwardOutline } from 'react-icons/io5';
import { SiAudiomack } from 'react-icons/si';

export const CallToActionConfig: ConfigType = {
  tags: {
    callToAction: {
      render: 'CallToAction',
      children: [],
      attributes: {
        className: {
          type: String,
          required: false,
        },
        title: {
          type: String,
          required: true,
        },
        img: {
          type: String,
          required: true,
        },
        logo: {
          type: String,
          required: false,
        },
      },
    },
  },
};

const logos: any = {
  moon: () => <IoMdMoon className="text-4xl text-white md:text-6xl" />,
  care: () => <Image height={48} width={48} src={care} alt="" />,
  audio: () => <SiAudiomack className="text-4xl text-white md:text-6xl" />,
  none: () => {},
};

interface CallToActionProps {
  children?: React.ReactNode[];
  className?: string;
  title: string;
  img: string;
  logo?: string;
}

export const CallToAction: React.FC<CallToActionProps> = ({
  className,
  title,
  children,
  img,
  logo = 'moon',
}) => {
  const pSlug = useProgramSlug();
  return (
    <div className="mb-20">
      <div
        className={classNames(
          'flex flex-col md:flex-row px-10 md:px-20 pt-20 md:pt-28 mx-3 md:mx-8',
          className
        )}
        style={{
          borderTopLeftRadius: '10rem',
          borderTopRightRadius: '2rem',
          borderBottomRightRadius: '2rem',
          background: `url(${img})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top',
        }}
      >
        <div className="md:w-1/2 md:px-20">
          <div
            className="text-left text-3xl font-semibold text-white md:text-5xl"
            style={{}}
          >
            {title}
          </div>
          <div className="my-10">{logo && logos[logo]()}</div>
        </div>
        <div className="md:w-1/2">
          <div className="md:mx-10">
            {children
              ? children.map((child, index) => {
                  return (
                    <div
                      key={index}
                      className="mb-10 text-left text-lg text-white md:text-2xl"
                    >
                      {child}
                    </div>
                  );
                })
              : null}
            <div className="my-16 md:my-32">
              <Link href={`/p/${pSlug}/course`}>
                <Button
                  className="border-white bg-transparent py-2"
                  style={{
                    width: '250px',
                    borderRadius: '5rem',
                    backgroundColor: 'transparent',
                  }}
                >
                  <div className="flex items-center rounded-full">
                    <div className="mr-1 text-xl font-bold">
                      Start the course
                    </div>
                    <div>
                      <IoArrowForwardOutline />
                    </div>
                  </div>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
